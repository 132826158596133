import React, { useEffect, useState } from 'react';
import './seoBlock.css';
import { getData } from "../../utils/customHooks/customHooks";

function SeoBlock({ apiUrl }) {
    const [seoBlockData, setSeoBlockData] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const lang = localStorage.getItem("language") || "";
const validLang = lang.trim() === "" ? "" : `/${lang}`;

    const getSeoBlockData = () => {
        getData(apiUrl).then(res => {
            setSeoBlockData(res);
        });
    };

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        getSeoBlockData();
    }, [apiUrl]);

    return (
        <>
            {seoBlockData && seoBlockData.block_seo_data && seoBlockData.block_seo_data.length > 0 && (
                <div className="seoBlock">
                    <div className="seoBlock__container container">
                        <div className="seoBlock__row">
                            <div className="seoBlock__content">
                                <h3 className="seoBlock__title">
                                    {lang === "en" ? seoBlockData.block_seo_data[0].title_eng :
                                        seoBlockData.block_seo_data[0].title}
                                </h3>
                                <p
                                    className={`seoBlock__text ${!isExpanded ? "seoBlock__text--collapsed" : ""}`}
                                    dangerouslySetInnerHTML={{
                                        __html: lang === "en" ? seoBlockData.block_seo_data[0].text_eng :
                                            seoBlockData.block_seo_data[0].text
                                    }}
                                />
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <button
                                        className="seoBlock__btn"
                                        onClick={toggleExpanded}
                                    >
                                        {isExpanded ? "Показати Менше" : "Показати Більше"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default SeoBlock;
