import React from 'react';
import "./bottom_menu.css";
import VscAccount from '../../assets/svg/VscAccount';
import { RiStore2Line } from "react-icons/ri";
import {Link} from "react-router-dom";
import { PiFlower } from "react-icons/pi";
import FcLike from '../../assets/svg/FcLike';
import { RiSearch2Line } from "react-icons/ri";


function BottomMenu({getStateShowSearch,showSearch}) {
    const lang = localStorage.getItem("language") || "";
const validLang = lang.trim() === "" ? "" : `/${lang}`;
    const [activeLink, setActiveLink] = React.useState('home');
    return (
        <div>
        <div className="bottom__menu">
            <div className="bottom__menu__container">
                <div className="bottom__menu__row">
                    <Link to={`${validLang}`} className={`bottom__menu__row__item ${activeLink==="home"?"active":''}`}
                          onClick={()=>{
                        setActiveLink("home");
                    }}>
                        <RiStore2Line />
                       <p className="bottom__menu__row__item__text">Головна</p>
                    </Link>
                    <Link to={`${validLang}/shop`} className={`bottom__menu__row__item ${activeLink==="shop"?"active":''}`}
                          onClick={()=>{
                        setActiveLink("shop");
                    }}>
                        <PiFlower />
                        <p className="bottom__menu__row__item__text">Каталог</p>
                    </Link>
                    <Link to={`${validLang}/personal-cabinet`}  className={`bottom__menu__row__item ${activeLink==="personal-cabinet"?"active":''}`}
                          onClick={()=>{
                        setActiveLink("personal-cabinet");
                    }}>
                        <VscAccount />
                        <p className="bottom__menu__row__item__text">Акаунт</p>
                    </Link>
                    <Link to={`${validLang}/liked`} className={`bottom__menu__row__item ${activeLink==="liked"?"active":''}`}
                          onClick={()=>{
                        setActiveLink("liked");
                    }}>
                        <FcLike className="bottom__menu__row__item__icon"/>
                        <p className="bottom__menu__row__item__text">Вибрані</p>
                    </Link>
                    <div className={`bottom__menu__row__item ${showSearch?"active":""}`}
                         onClick={()=>{
                        getStateShowSearch(!showSearch)
                    }}>
                        <RiSearch2Line />
                        <p className="bottom__menu__row__item__text">Пошук</p>
                    </div>
                </div>
            </div>
        </div>

        </div>
    );
}

export default BottomMenu;