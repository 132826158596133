import React from 'react';
import {motion} from "framer-motion";
import {MdKeyboardArrowRight} from "react-icons/md";
import {FaFacebook, FaInstagram, FaTelegram, FaTiktok, FaViber} from "react-icons/fa";
import {Link} from "react-router-dom";
import {IoIosArrowDown} from "react-icons/io";
import { useTranslation } from 'react-i18next';
import '../../language/i18n';
function HeaderBurgerMenu({getStateShowBurgerMenu,getActiveBurgerOption}) {
    const { t } = useTranslation();
    const lang = localStorage.getItem("language") || "";
const validLang = lang.trim() === "" ? "" : `/${lang}`;

    return (
        <div>
            <motion.div
                initial={{opacity: 0, y: -50}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: -50}}
                transition={{duration: 0.3}}
                className="header__burger">
                <div className="header__burger__menu__container">
                    <ul className="header__burger__menu">
                        <li className="header__burger__menu__item" onClick={() => {
                            getStateShowBurgerMenu(false)
                        }}>
                            <Link to={`${validLang}`} className="header__burger__menu__link">
                                {t('nav_home')}
                            </Link>
                        </li>
                        <li className="header__burger__menu__item" onClick={() => {
                            getActiveBurgerOption('categories')
                        }}>
                            <a className="header__burger__menu__link">
                                {t('nav_shop')}
                            </a>
                            <MdKeyboardArrowRight className="header__burger__menu__item__icon"/>
                        </li>
                        <li className="header__burger__menu__item" onClick={() => {
                            getStateShowBurgerMenu(false)
                        }}>
                            <Link to={`${validLang}/about-us`} className="header__burger__menu__link">
                                {t('nav_about_us')}
                            </Link>
                        </li>
                        <li className="header__burger__menu__item" onClick={() => {
                            getStateShowBurgerMenu(false)
                        }}>
                            <Link to={`${validLang}/contacts`} className="header__burger__menu__link">
                                {t('nav_contact')}
                            </Link>
                        </li>
                        <li className="header__burger__menu__item" onClick={() => {
                            getStateShowBurgerMenu(false)
                        }}>
                            <Link to={`${validLang}/return-conditions-info`} className="header__burger__menu__link">
                                {t('nav_return_conditions_info')}
                            </Link>
                        </li>
                        <li className="header__burger__menu__item" onClick={() => {
                            getStateShowBurgerMenu(false)
                        }}>
                            <Link to={`${validLang}/delivery-and-payments-info`} className="header__burger__menu__link">
                                {t('nav_delivery_info')}
                            </Link>
                        </li>
                        <li className="header__burger__menu__item" onClick={() => {
                            getStateShowBurgerMenu(false)
                        }}>
                            <Link to={lang === "en" ? "/en/faq" : "/faq"} className="header__burger__menu__link">
                                FAQ
                            </Link>
                        </li>

                        {/*<Link to="/blog" className="header__burger__menu__link">*/}
                        {/*    Блог*/}
                        {/*</Link>*/}
                    </ul>
                    {/*<div className="header__burger__controller__item">*/}
                    {/*    <p className="header__burger__controller__item__city" onClick={()=>{*/}
                    {/*        setShowChooseYourCityModal(true);*/}
                    {/*    }}>*/}
                    {/*        {!localStorage.getItem("city")?"Львів":JSON.parse(localStorage.getItem("city"))} <IoIosArrowDown />*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    <div className="header__burger__social__link">
                        <a href="https://www.instagram.com/murmur.flowers.lviv?igsh=ZG13dmtiMGJ0NHJt">
                            <FaInstagram className={"header__burger__social__link__icon"}/>
                        </a>
                        <a href="https://t.me/murmurflowers_lviv">
                            <FaTelegram className={"header__burger__social__link__icon"}/>
                        </a>
                        <a href="https://m.facebook.com/murmur.flowers.lviv/">
                            <FaFacebook className={"header__burger__social__link__icon"}/>
                        </a>
                        <a href="viber://chat?number=%20%2B380670099360">
                            <FaViber className={"header__burger__social__link__icon"}/>
                        </a>
                    </div>

                </div>
            </motion.div>
        </div>
    );
}

export default HeaderBurgerMenu;