import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import { FcGoogle } from "react-icons/fc";
import {MdKeyboardArrowRight, MdOutlineReviews} from "react-icons/md";
import "./reviews.css";
import {Pagination} from 'swiper/modules';
import 'swiper/css/pagination';
import {useTranslation} from "react-i18next";
import '../../language/i18n';
import {getData} from "../../utils/customHooks/customHooks";
import RewiewsRatingStar from "./rewiewsRatingStar";
import {Link} from "react-router-dom";

function Reviews() {
    const {t} = useTranslation();
    const [comments, setComments] = useState([]);
    const lang = localStorage.getItem("language") || "";
const validLang = lang.trim() === "" ? "" : `/${lang}`;

    const getAllComments = () => {
        getData('/api/main_reviwed/').then(res => {
            setComments(res)
        })
    }
    useEffect(()=>{
        getAllComments();
    },[])

    return (
        <>
            {comments && comments.length!==0 &&
                <div className="reviews" id="reviews">
                    <div className="reviews__container container">
                        <div className="reviews__header">
                            <h2 className="reviews__title main__title container"> {t('main_reviews')}</h2>
                            <div className="reviews__header__btn ">
                                <Link to= {`${validLang}/reviews`} className="storefront__btn__link ">
                                    {t("all_reviews")}
                                </Link>
                                <MdKeyboardArrowRight />
                            </div>
                        </div>


                        <div className="reviews__swiper__container ">
                            <Swiper
                                loop={true}
                                speed={1000}
                                pagination={true}
                                modules={[Pagination]}
                                slidesPerView={2}
                                spaceBetween={5}
                                className="mySwiper reviews__swiper container"
                                breakpoints={{
                                    700: {
                                        spaceBetween: 20,
                                        slidesPerView: 3,
                                    }
                                }}
                            >
                                {comments.map((comment, index) => (
                                    <SwiperSlide className="reviews__swiper__slide" key={index}>
                                        <div className="reviews__swiper__slide__content">
                                            <div style={{display:'flex', justifyContent:'space-between', alignItems: 'center'}}>
                                                <div style={{display:'flex', flexDirection: 'column', gap: 10}}>
                                                    <p className="reviews__author-name">
                                                        {lang === "en" ? comment.username_eng : comment.username}
                                                    </p>
                                                    <RewiewsRatingStar comment={comment} index={index}/>
                                                </div>
                                                <FcGoogle style={{fontSize: '25px', color: '#535353'}}></FcGoogle>
                                            </div>
                                            <p className="reviews__text-comment" style={{marginBottom: "15px"}}>
                                                {lang === "en" ? <>
                                                    {comment.text_eng.length > 100 ? comment.text.slice(0, 100) + "..." : comment.text_eng}
                                                </>
                                                :<>
                                                    {comment.text.length > 100 ? comment.text.slice(0, 100) + "..." : comment.text}
                                                </>}
                                            </p>
                                            <div style={{position: "absolute", bottom: "15px"}} className='review__swiper_created_at'>
                                                {comment.created_at}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                                }

                            </Swiper>

                        </div>

                    </div>
                </div>
            }


        </>
    );
}

export default Reviews;