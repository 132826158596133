import React, {useEffect, useState} from 'react';
import "./categoriesOnHomePage.css";
import {useTranslation} from "react-i18next";
import {getData} from "../../utils/customHooks/customHooks";
import {Swiper, SwiperSlide} from "swiper/react";
import {setCategories} from "../../store/authSlice/categoriesSlice";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";


function CategoriesOnHomePage() {
    const {t} = useTranslation();
    const [allCategories, setAllCategories] = useState(null);
    const lang = localStorage.getItem("language") || "";
const validLang = lang.trim() === "" ? "" : `/${lang}`;
    const dispatch = useDispatch();

    const getAllCategories = () => {
        getData("/api/categories/").then(res => {
            setAllCategories(res)
        })
    }
    useEffect(() => {
        getAllCategories()
    }, []);

    return (
        <>
            <section className="categories_on_home_page">
                <div className="categories_on_home_page__container container">
                    <h2 className="categories_on_home_page__title main__title">
                        {t('shop_category')}
                    </h2>
                    <div className="categories_on_home_page__swiper__container ">
                        <Swiper
                            loop={true}
                            speed={1000}
                            pagination={true}
                            slidesPerView={"auto"}
                            spaceBetween={10}
                            className="mySwiper categories_on_home_page__swiper container"
                        >
                            {
                                allCategories && allCategories.map(category => (
                                    <SwiperSlide className="categories_on_home_page__swiper__slide" >
                                        <Link to={`${validLang}/shop/category_slug=${category.slug}`} className="categories_on_home_page__row__item" onClick={()=>{
                                            dispatch((setCategories({
                                                "category_name": lang === "en" ? category.name_eng : category.name
                                            })))
                                        }
                                        }>
                                            {lang === "en" ? category.name_eng : category.name}
                                        </Link>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CategoriesOnHomePage;