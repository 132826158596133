import React from 'react';
import './notFoundPage.css';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
function NotFoundPage() {
    const {t} = useTranslation();
    const lang = localStorage.getItem("language") || "";
const validLang = lang.trim() === "" ? "" : `/${lang}`;
    return (
        <>
            <div className="not_found_page">
                <div className="not_found_page__container container" >
                    <div className="not_found_page__row">
                        <h6 className="not_found_page_title">
                            404
                        </h6>
                        <p className="not_found_page_sub_title">
                            {t("404_sub_titles")}
                        </p>
                        <Link to={`${validLang}`} className="not_found_page__btn main-btn" >
                            {t("nav_home")}
                        </Link>
                    </div>

                </div>
            </div>




        </>
    );
}

export default NotFoundPage;