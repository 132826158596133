import './main.css';
import React, {lazy, Suspense, useEffect, useState} from "react";
import i18n from './language/i18n';
import {Helmet} from "react-helmet";
import 'swiper/css';
import 'swiper/css/navigation';
import {Route, BrowserRouter, Routes, Navigate, useParams} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import Search from "./components/search/search";
import Header from "./components/header/header";
import Homepage from "./components/homepage/homepage";
import Footer from "./components/footer/footer";
import QuickDial from "./components/quickDial/quickDial";
import NotFoundPage from "./components/404/notFoundPage";
import Bottom__menu from "./components/bottomMenu/bottom__menu";
import {getData, postData} from "./utils/customHooks/customHooks";
import TagManager from 'react-gtm-module';
import ContinueShopingModal from "./components/continueShopingModal/continueShopingModal";
import ReviewsPage from "./components/reviewsPage/reviewsPage";
import { useNavigate } from 'react-router-dom';
import Blog from "./components/blog/blog";
import BlogArticle from "./components/blog/blogArticle";
import BlogCategory from "./components/blog/blogCategory";
import {Loader} from "rsuite";
import SpeedTest from "./components/speedTest/speedTest";
import SuccessPayment from "./components/paymentAndDeliveryMainInfo/SuccessPayment";


function App() {

    const [showSearch, setShowSearch] = useState(false);
    const [showBurgerMenu, setShowBurgerMenu] = useState(false);
    // const [showChooseYourCityModal , setShowChooseYourCityModal] = useState(!localStorage.getItem("city"))
    const [changeLanguage, setChangeLanguage] = useState(false);
    const [block1, setBlok1] = useState(null);
    const [block2, setBlok2] = useState(null);
    const [analyticsCode, setAnalyticsCode] = useState(null);
    const AuthPageRegistration = lazy(() => import('./components/authPage/authPageRegistration'));
    const AuthPageLogin = lazy(() => import('./components/authPage/authPageLogin'));
    const PersonalCabinet = lazy(() => import('./components/personalCabinet/personalCabinet'));
    const Shop = lazy(() => import('./components/shop/shop'));
    const ProductPage = lazy(() => import('./components/productPage/productPage'));
    const AdditionalProductPage = lazy(() => import('./components/productPage/additionalProductPage'));
    const AboutUs = lazy(() => import('./components/aboutUs/aboutUs'));
    const ShoppingCart = lazy(() => import('./components/shopingCart/shoppingCart'));
    const PaymentAndDeliveryMainInfo = lazy(() => import('./components/paymentAndDeliveryMainInfo/paymentAndDeliveryMainInfo'));
    const LikedProducts = lazy(() => import('./components/likedProducts/likedProducts'));
    const OrderPaymentsForm = lazy(() => import('./components/orderPaymentsForm/orderPaymentsForm'));
    const Contacts = lazy(() => import('./components/contacts/contacts'));
    const Faq = lazy(() => import('./components/faq/faq'));
    const CategoriesPage = lazy(() => import('./components/categoriesPage/categoriesPage'));
    const BlogAuthorPage = lazy(() => import('./components/blog/blogAuthor'));
    const [showContinueShopingModal , setShowContinueShopingModal] = useState(false);
    const [countLikedProducts ,setCountLikedProducts ] = useState(0)
    const [isLoading, setIsLoading] = useState(true);
    const [isPageSpeedInsights, setIsPageSpeedInsights] = useState(false);

    React.useEffect(() => {
        const userAgent = window.navigator.userAgent;
        const isPageSpeed = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Safari/537.36" === userAgent || "Mozilla/5.0 (Linux; Android 11; moto g power (2022)) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Mobile Safari/537.36" === userAgent;
        setIsPageSpeedInsights(isPageSpeed);
    }, []);


    const LazyCategoriesPage = () => (
        <Suspense fallback={<Loader />}>
            <CategoriesPage/>
        </Suspense>
    );
    const LazyAuthPageRegistration = () => (
        <Suspense fallback={<Loader />}>
            <AuthPageRegistration/>
        </Suspense>
    );
    const LazyAuthPageLogin = () => (
        <Suspense fallback={<Loader />}>
            <AuthPageLogin/>
        </Suspense>
    );
    const LazyPersonalCabinet = () => (
        <Suspense fallback={<Loader />}>
            <PersonalCabinet/>
        </Suspense>
    );
    const LazyShop = () => (
        <Suspense fallback={<Loader />}>
            <Shop setShowContinueShopingModal={setShowContinueShopingModal}
                  showContinueShopingModal={showContinueShopingModal} setCountLikedProducts={setCountLikedProducts}/>
        </Suspense>
    );
    const LazyProductPage = () => (
        <Suspense fallback={<Loader />}>
            <ProductPage showContinueShopingModal={showContinueShopingModal} setShowContinueShopingModal={setShowContinueShopingModal}
                         setCountLikedProducts={setCountLikedProducts}/>
        </Suspense>
    );
    const LazyAboutUs = () => (
        <Suspense fallback={<Loader />}>
            <AboutUs/>
        </Suspense>
    );
    const LazySuccessPayment = () => (
        <Suspense fallback={<Loader />}>
            <SuccessPayment/>
        </Suspense>
    );
    const LazyShoppingCart = () => (
        <Suspense fallback={<Loader />}>
            <ShoppingCart/>
        </Suspense>
    );
    const LazyPaymentAndDeliveryMainInfo = ({type}) => (

        <Suspense fallback={<Loader />}>
            <PaymentAndDeliveryMainInfo type={type}/>
        </Suspense>
    );
    const LazyLikedProducts = () => (
        <Suspense fallback={<Loader />}>
            <LikedProducts showContinueShopingModal={showContinueShopingModal} setShowContinueShopingModal={setShowContinueShopingModal}
                           setCountLikedProducts={setCountLikedProducts}/>
        </Suspense>
    );
    const LazyOrderPaymentsForm = () => (
        <Suspense fallback={<Loader />}>
            <OrderPaymentsForm/>
        </Suspense>
    );
    const LazyContacts = () => (
        <Suspense fallback={<Loader />}>
            <Contacts/>
        </Suspense>
    );
    const LazyFaq = () => (
        <Suspense fallback={<Loader />}>
            <Faq/>
        </Suspense>
    );
    const BlogArticleDetails = () => (
        <Suspense fallback={<Loader />}>
            <BlogArticle/>
        </Suspense>
    );
    const BlogCategoryDetails = () => (
        <Suspense fallback={<Loader />}>
            <BlogCategory/>
        </Suspense>
    );
    const BlogAuthor = () => (
        <Suspense fallback={<Loader />}>
            <BlogAuthorPage/>
        </Suspense>
    );
    const LazyReviews = () => (
        <Suspense fallback={<Loader />}>
            <ReviewsPage/>
        </Suspense>
    );
    const LazyAdditionalProductPage = () => (
        <Suspense fallback={<Loader />}>
            <AdditionalProductPage/>
        </Suspense>
    );
    const getStateShowSearch = (state) => {
        setShowSearch(state)
    }
    const getStateShowBurgerMenu = (state) => {
        setShowBurgerMenu(state)
    }
    useEffect(() => {
        Promise.all([
            getData('/api/get_product_with_block1/').then(res => setBlok1(res)),
            getData('/api/get_product_with_block2/').then(res => setBlok2(res)),
            getData("/api/shop/analytics_code/").then(res => {
                setAnalyticsCode(res);
                TagManager.initialize({
                    gtmId: res.code_google_analytics
                });
            }),
            getData("/api/like/get_like_to_product").then(res => setCountLikedProducts(res.length))
        ]).finally(() => setIsLoading(false));
    }, []);

useEffect(() => {
    const pathSegment = window.location.pathname.split("/")[1]; // Get first path segment
    if (pathSegment === "en") {
        localStorage.setItem("language", JSON.stringify("en"));
        i18n.changeLanguage("en");
    } else {
        localStorage.setItem("language", "");
        i18n.changeLanguage("ua");
    }
}, []);

    return (
        <>
            {isPageSpeedInsights
                ? <SpeedTest></SpeedTest>
                : <BrowserRouter>
                    {isLoading ? <Loader/> : (
                        <div className="App">
                            <Helmet>
                                <link rel="alternate" hreflang="en" href="https://murmurflowers.com/en"/>
                                <link rel="alternate" hreflang="ua" href="https://murmurflowers.com/"/>
                            </Helmet>

                            <QuickDial/>
                            <AnimatePresence>
                                {showSearch && <Search getStateShowSearch={getStateShowSearch}/>}
                            </AnimatePresence>
                            <Header countLikedProducts={countLikedProducts} changeLanguage={changeLanguage}
                                    setChangeLanguage={setChangeLanguage}
                                    getStateShowSearch={getStateShowSearch} showSearch={showSearch}
                                    showBurgerMenu={showBurgerMenu}
                                    getStateShowBurgerMenu={getStateShowBurgerMenu}
                            />
                            <main className="main">
                                <Routes>
                                    {/* Redirect root based on language */}
                                    <Route path="/" element={<Homepage setCountLikedProducts={setCountLikedProducts}
                                                                       showContinueShopingModal={showContinueShopingModal}
                                                                       setShowContinueShopingModal={setShowContinueShopingModal}
                                                                       block1={block1} block2={block2}/>}/>
                                    <Route path="/en" element={<Homepage setCountLikedProducts={setCountLikedProducts}
                                                                         showContinueShopingModal={showContinueShopingModal}
                                                                         setShowContinueShopingModal={setShowContinueShopingModal}
                                                                         block1={block1} block2={block2}/>}/>

                                    {/* Ukrainian (default) routes */}
                                    <Route path="/personal-cabinet" element={<LazyPersonalCabinet />} />
                                    <Route path="/registration" element={<LazyAuthPageRegistration />} />
                                    <Route path="/login" element={<LazyAuthPageLogin />} />
                                    <Route path="/shop/:params?/:page?" element={<LazyShop />} />
                                    <Route path="/flower-page/:id/:slug" element={<LazyProductPage />} />
                                    <Route path="/additional-product/:id" element={<LazyAdditionalProductPage />} />
                                    <Route path="/about-us" element={<LazyAboutUs />} />
                                    <Route path="/liked" element={<LazyLikedProducts />} />
                                    <Route path="/payments" element={<LazyOrderPaymentsForm />} />
                                    <Route path="/cart" element={<LazyShoppingCart />} />
                                    <Route path="/delivery-and-payments-info" element={<LazyPaymentAndDeliveryMainInfo type="delivery-and-payments" />} />
                                    <Route path="/return-conditions-info" element={<LazyPaymentAndDeliveryMainInfo type="return-conditions" />} />
                                    <Route path="/contacts" element={<LazyContacts />} />
                                    <Route path="/faq" element={<LazyFaq />} />
                                    <Route path="/categories" element={<LazyCategoriesPage />} />
                                    <Route path="/blog" element={<Blog />} />
                                    <Route path="/blog/:slug" element={<BlogArticleDetails />} />
                                    <Route path="/blog/author/:slug" element={<BlogAuthor />} />
                                    <Route path="/blog/category/:slug" element={<BlogCategoryDetails />} />
                                    <Route path="/reviews" element={<LazyReviews />} />
                                    <Route path="/payment_success" element={<LazySuccessPayment />} />

                                    {/* English routes with "/en" prefix */}
                                    <Route path="/en/personal-cabinet" element={<LazyPersonalCabinet />} />
                                    <Route path="/en/registration" element={<LazyAuthPageRegistration />} />
                                    <Route path="/en/login" element={<LazyAuthPageLogin />} />
                                    <Route path="/en/shop/:params?/:page?" element={<LazyShop />} />
                                    <Route path="/en/flower-page/:id/:slug" element={<LazyProductPage />} />
                                    <Route path="/en/additional-product/:id" element={<LazyAdditionalProductPage />} />
                                    <Route path="/en/about-us" element={<LazyAboutUs />} />
                                    <Route path="/en/liked" element={<LazyLikedProducts />} />
                                    <Route path="/en/payments" element={<LazyOrderPaymentsForm />} />
                                    <Route path="/en/cart" element={<LazyShoppingCart />} />
                                    <Route path="/en/delivery-and-payments-info" element={<LazyPaymentAndDeliveryMainInfo type="delivery-and-payments" />} />
                                    <Route path="/en/return-conditions-info" element={<LazyPaymentAndDeliveryMainInfo type="return-conditions" />} />
                                    <Route path="/en/contacts" element={<LazyContacts />} />
                                    <Route path="/en/faq" element={<LazyFaq />} />
                                    <Route path="/en/categories" element={<LazyCategoriesPage />} />
                                    <Route path="/en/blog" element={<Blog />} />
                                    <Route path="/en/blog/:slug" element={<BlogArticleDetails />} />
                                    <Route path="/en/blog/author/:slug" element={<BlogAuthor />} />
                                    <Route path="/en/blog/category/:slug" element={<BlogCategoryDetails />} />
                                    <Route path="/en/reviews" element={<LazyReviews />} />
                                    <Route path="/en/payment_success" element={<SuccessPayment />} />

                                    {/* 404 Page */}
                                    <Route path="*" element={<NotFoundPage />} />
                                </Routes>


                            </main>
                            {block1 && block2 && <Footer block1={block1} block2={block2}/>}
                            <Bottom__menu getStateShowSearch={getStateShowSearch} showSearch={showSearch}/>
                        </div>
                    )}
                </BrowserRouter>
            }
        </>
    );
}

export default App;
