import React, {useEffect, useState} from 'react';
import {getData} from "../../utils/customHooks/customHooks";
import {Link} from "react-router-dom";
import {MdKeyboardArrowRight} from "react-icons/md";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper/modules";
import RewiewsRatingStar from "../reviews/rewiewsRatingStar";
import {useTranslation} from "react-i18next";
import "../reviews/reviews.css";


const BlogPreview = () => {
    const {t} = useTranslation();
    const lang = localStorage.getItem("language") || "";
const validLang = lang.trim() === "" ? "" : `/${lang}`;
    const [productsTop5, setProductsTop5] = useState([])


    useEffect(() => {

        getData("/api/blog/articles/").then(res => {
            setProductsTop5(res.top_five_articles)
        })
    }, [])
    return (
                <>
            {productsTop5 && productsTop5.length!==0 &&
                <div className="reviews" id="reviews">
                    <div className="reviews__container container">
                        <div className="reviews__header">
                            <h2 className="reviews__title main__title container">{t("blog_preview")}</h2>
                            <div className="reviews__header__btn ">
                                <Link to= {`${validLang}/blog`} className="storefront__btn__link ">
                                    {t("more")}
                                </Link>
                                <MdKeyboardArrowRight />
                            </div>
                        </div>


                        <div className="reviews__swiper__container ">
                            <Swiper
                                loop={true}
                                speed={1000}
                                pagination={true}
                                modules={[Pagination]}
                                slidesPerView={3}
                                spaceBetween={20}
                                className="mySwiper reviews__swiper container"
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                    },

                                    768: {
                                        slidesPerView: 2,
                                    },
                                    1000: {
                                        slidesPerView: 3,
                                    },
                                }}
                            >
                                {productsTop5.map((article, index) => (
                                    <SwiperSlide
  className="blog_preview"
  key={index}
  style={{
    position: "relative",
    overflow: "hidden",
  }}
>
                            <Link to={`${validLang}/blog/${article.slug}`} style={{color: 'black'}}>

  <div
    style={{
      backgroundImage: article.img ? `url(${article.img})` : 'none',
      backgroundSize: "cover",
      backgroundPosition: "center",
      filter: "brightness(0.5)",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
    }}
  ></div>

  <div
    style={{
      position: "relative",
      zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    }}
  >
    <div style={{
            color: "white"}}>{article.name}</div>
    <div style={{
        color: "white"
    }}>{article.description}</div>
  </div>
                                </Link>
</SwiperSlide>
                            ))
                                }

                            </Swiper>

                        </div>

                    </div>
                </div>
            }


        </>
    );
};

export default BlogPreview;