import React, {useEffect, useState} from 'react';
import "./footer.css";
import {FaInstagram, FaTelegram} from "react-icons/fa";
import {FaFacebook} from "react-icons/fa";
import {FaViber} from "react-icons/fa";
import {FaTiktok} from "react-icons/fa";
import {Link, useLocation} from "react-router-dom";
import {setScroll} from "../../store/authSlice/scrollSlice";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import '../../language/i18n';
import {getData} from "../../utils/customHooks/customHooks";
import {setCartLength} from "../../store/authSlice/cartSlice";
function Footer({block1,block2}) {
    const {t} = useTranslation();
    const [isVisible, setIsVisible] = useState(true);
    const dispatch = useDispatch();
    const lang = localStorage.getItem("language") || "";
const validLang = lang.trim() === "" ? "" : `/${lang}`;



    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            if (scrollPosition > 30) {
                setIsVisible(false);
            } else {
                setIsVisible(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);


    return (
        <div>
            <div className="footer">
                <div className="footer__container container">
                    <div className="footer__row">
                        <div className="footer__row__item">
                            <h4 className="footer__row__item__title">
                                {t('nav_home')}
                            </h4>
                            <ul className="footer__row__item__links">
                                <li className="footer__row__item__links__item">
                                    <Link to={`${validLang}`} className="footer__row__item__link" onClick={()=>{
                                        dispatch(setScroll({
                                            scroll__id :block1.name_eng
                                        }))
                                    }} >
                                        {lang==="en"? block1.name_eng:block1.name}
                                    </Link>
                                </li>
                                <li className="footer__row__item__links__item">
                                    <Link to={`${validLang}`} className="footer__row__item__link" onClick={()=>{
                                        dispatch(setScroll({
                                            scroll__id :block2.name_eng
                                        }))
                                    }} >
                                        { lang==="en"? block2.name_eng:block2.name}
                                    </Link>
                                </li>
                                <li className="footer__row__item__links__item">
                                    <Link to={`${validLang}/contacts`} className="footer__row__item__link" onClick={()=>{
                                        dispatch(setScroll({
                                            scroll__id :"contacts"
                                        }))
                                    }} >
                                        {t('nav_contact')}
                                    </Link>
                                </li>
                                <li className="footer__row__item__links__item">
                                    <Link to={`${validLang}/reviews`} className="footer__row__item__link" >
                                        {t('nav_reviews')}
                                    </Link>
                                </li>
                                <li className="footer__row__item__links__item">
                                    <Link to={`${validLang}/shop`}className="footer__row__item__link">
                                        {t('nav_shop')}
                                    </Link>
                                </li>

                            </ul>
                        </div>
                        <div className="footer__row__item">
                            <h4 className="footer__row__item__title">
                                MUR MUR Flowers
                            </h4>
                            <ul className="footer__row__item__links">
                                <li className="footer__row__item__links__item">
                                    <Link to={`${validLang}/about-us`} className="footer__row__item__link">
                                        {t('nav_about_us')}
                                    </Link>
                                </li>
                                {/*<li className="footer__row__item__links__item">*/}
                                {/*    <Link to="/blog" className="footer__row__item__link">*/}
                                {/*       Блог*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li className="footer__row__item__links__item">
                                    <Link to={`${validLang}/contacts`} className="footer__row__item__link"  >
                                        {t('nav_contact_us')}
                                    </Link>
                                </li>



                            </ul>
                        </div>
                        <div className="footer__row__item">
                            <h4 className="footer__row__item__title">
                                FAQ
                            </h4>
                            <ul className="footer__row__item__links">
                                <li className="footer__row__item__links__item">
                                    <Link to={`${validLang}/return-conditions-info`}className="footer__row__item__link">
                                        {t('nav_return_conditions_info')}
                                    </Link>

                                </li>
                                <li className="footer__row__item__links__item">
                                    <Link to={`${validLang}/delivery-and-payments-info`}className="footer__row__item__link">
                                        {t('nav_delivery_info')}
                                    </Link>
                                </li>


                            </ul>
                        </div>
                    </div>
                    <div className="footer__row__social">
                        <p className="footer__row__social__title">
                            {t('footer_social')}
                        </p>
                        <div className="footer__row__social-box">

                            <Link to="https://www.instagram.com/murmur.flowers.lviv?igsh=ODM5NWdzZDAxdzEx"
                                  className="footer__row__social-box__link">
                                <FaInstagram/>
                            </Link>
                            <Link to="https://t.me/murmurflowers_lviv"
                                  className="footer__row__social-box__link">
                                <FaTelegram/>
                            </Link>
                            <Link to="https://m.facebook.com/murmur.flowers.lviv/"
                                  className="footer__row__social-box__link">
                                <FaFacebook/>
                            </Link>
                            <Link to="viber://chat?number=%20%2B380670099360"
                                  className="footer__row__social-box__link">
                                <FaViber/>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Footer;