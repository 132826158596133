import React, {useEffect, useState} from 'react';
import "./why-us.css"
import '../../language/i18n';

import {useTranslation} from "react-i18next";
import {getData} from "../../utils/customHooks/customHooks";
import whyus1 from "../../assets/images/whyus1.JPG"
import whyus2 from "../../assets/images/whyus2.JPG"
import whyus3 from "../../assets/images/whyus3.JPG"
import whyus4 from "../../assets/images/whyus4.JPG"
import whyus5 from "../../assets/images/whyus5.PNG"
import whyus6 from "../../assets/images/whyus6.JPG"
import whyus7 from "../../assets/images/whyus7.JPG"
import whyus8 from "../../assets/images/whyus8.PNG"
import {Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";

function WhyUs() {
    const [activeAdvantageId, setActiveAdvantageId] = useState(0)
    const {t} = useTranslation();
    const [advantages, setAdvantages] = useState([]);
    const lang = localStorage.getItem("language") || "";
const validLang = lang.trim() === "" ? "" : `/${lang}`;


    return (


            <Swiper
                                loop={true}
                                speed={1000}
                                pagination={true}
                                modules={[Pagination]}
                                slidesPerView={4}
                                spaceBetween={20}
                                className="mySwiper reviews__swiper"
                                breakpoints={{
        // When the viewport width is 768px or smaller
        1200: {
            slidesPerView: 8, // 4 слайди на екрані
        }
    }}
                            >
            <SwiperSlide className='why-us_card'>
                <div style={{width: '100%'}}>
                    <img src={whyus1} alt="" width='100%'/>
                </div>

                <div className={'why-us_title'}>{t("why_us1")}</div>
            </SwiperSlide>
            <SwiperSlide className='why-us_card'>
                <div style={{width: '100%'}}>
                    <img src={whyus2} alt="" width='100%'/>
                </div>
                <div className={'why-us_title'}>{t("why_us2")}</div>
            </SwiperSlide>
            <SwiperSlide className='why-us_card'>
                <div style={{width: '100%'}}>
                    <img src={whyus3} alt="" width='100%'/>
                </div>
                <div className={'why-us_title'}>{t("why_us3")}</div>
            </SwiperSlide>
            <SwiperSlide className='why-us_card'>
                <div style={{width: '100%'}}>
                    <img src={whyus4} alt="" width='100%'/>
                </div>
                <div className={'why-us_title'}>{t("why_us4")}</div>
            </SwiperSlide>
            <SwiperSlide className='why-us_card'>
                <div style={{width: '100%'}}>
                    <img src={whyus5} alt="" width='100%'/>
                </div>
                <div className={'why-us_title'}>{t("why_us5")}</div>
            </SwiperSlide>
            <SwiperSlide className='why-us_card'>
                <div style={{width: '100%'}}>
                    <img src={whyus6} alt="" width='100%'/>
                </div>
                <div className={'why-us_title'}>{t("why_us6")}</div>
            </SwiperSlide>
            <SwiperSlide className='why-us_card'>
                <div style={{width: '100%'}}>
                    <img src={whyus7} alt="" width='100%'/>
                </div>
                <div className={'why-us_title'}>{t("why_us7")}</div>
            </SwiperSlide>
            <SwiperSlide className='why-us_card'>
                <div style={{width: '100%'}}>
                    <img src={whyus8} alt="" width='100%'/>
                </div>
                <div className={'why-us_title'}>{t("why_us8")}</div>
            </SwiperSlide>
        </Swiper>
    );
}

export default WhyUs;