import React, {useEffect, useState} from 'react';
import './reviewsPage.css';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getData} from "../../utils/customHooks/customHooks";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import ReviewsPageComments from "./reviewsPageComments";
import {MdKeyboardArrowRight} from "react-icons/md";
import RewiewsRatingStar from "../reviews/rewiewsRatingStar";
import {BsGoogle} from "react-icons/bs";
import {SwiperSlide} from "swiper/react";
import {FcGoogle} from "react-icons/fc";

function ReviewsPage() {
    const {t} = useTranslation();
    const lang = localStorage.getItem("language") || "";
const validLang = lang.trim() === "" ? "" : `/${lang}`;
    const [comments, setComments] = useState([]);

    const getAllComments = () => {
        getData('/api/main_reviwed/').then(res => {
            setComments(res)
        })
    }

    useEffect(() => {
        getAllComments();
    }, [])

    return (
        <>
            <section className="reviews__page">
                <div className="reviews__page__container container">
                    <div className="page__navigation container">
                        <Link to={`${validLang}`} className="page__navigation__link">
                            {t("nav_home")}
                        </Link>
                        <MdKeyboardArrowRight/>
                        <Link
                            to={`${validLang}/reviews`}
                            className="page__navigation__link">
                            {t('product_page_review')}
                        </Link>
                    </div>
                    <h1 className="reviews__page__title">
                        {t('product_page_review')}
                    </h1>
                        <div style={{display: 'flex', gap: 5, flexWrap: 'wrap', width: '100%', marginTop: 25}}>
                        {
                            comments.map((comment, index) => (
                               <div className="reviews__swiper__slide reviews__swiper__slide_p" key={index}>
                                    <div className="reviews__swiper__slide__content">
                                        <div style={{display:'flex', justifyContent:'space-between', alignItems: 'center'}}>
                                            <div style={{display:'flex', flexDirection: 'column', gap: 10, width: '90%'}}>
                                                <p className="reviews__author-name">
                                                    {lang === "en" ? comment.username_eng : comment.username}
                                                </p>
                                                <RewiewsRatingStar comment={comment} index={index}/>
                                            </div>
                                            <FcGoogle style={{fontSize: '25px', color: '#535353'}}></FcGoogle>
                                        </div>
                                        <p className="reviews__text-comment" style={{marginBottom: "15px"}}>
                                            {lang === "en" ? comment.text_eng : comment.text}
                                        </p>
                                        <div className='review__swiper_created_at'>
                                            {comment.created_at}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                </div>
            </section>
        </>
    );
}

export default ReviewsPage;