import React, { useState} from 'react';
import "./continueShopingModal.css"
import {IoCloseOutline} from "react-icons/io5";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function ContinueShopingModal({showContinueShopingModal,setShowContinueShopingModal}) {
    const lang = localStorage.getItem("language") || "";
const validLang = lang.trim() === "" ? "" : `/${lang}`;
    const {t} = useTranslation();

    return (
        <>
            <div className="continue__shopin_modal">
                <div className="continue__shopin_modal__container">
                    <IoCloseOutline className="continue__shopin_modal__close__btn" onClick={()=>{
                        setShowContinueShopingModal(false)
                    }}/>
                    <div className="continue__shopin_modal__btn__row">
                     <p className="continue__shopin_modal__btn main-btn" onClick={()=>{
                         setShowContinueShopingModal(false)
                     }}>{t('continue_shopping')}</p>
                        <Link to={`${validLang}/cart`} className="continue__shopin_modal__btn main-btn" onClick={()=>{
                            setShowContinueShopingModal(false)
                        }}>{t('go_to_cart')}</Link>
                    </div>

                </div>

            </div>

        </>
    );
}

export default ContinueShopingModal;