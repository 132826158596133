import "./storeFront.css"
import StoreFrontCard from "./storeFrontCard";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import { MdKeyboardArrowRight } from "react-icons/md";
import 'swiper/css/pagination';
import {Link} from "react-router-dom";


function StoreFront({products, block,showContinueShopingModal,setShowContinueShopingModal,setCountLikedProducts}) {
    const lang = localStorage.getItem("language") || "";
const validLang = lang.trim() === "" ? "" : `/${lang}`;

    return (
        <>
            <div className="storefront" id={block.name_eng} >
                <div className="storefront__container ">
                    <div className="storefront__header container">
                        <h3 className="storefront__title ">
                            {lang==="en"? block.name_eng:block.name}
                        </h3>
                        <div className="storefront__btn ">
                            <Link to= {`${validLang}/shop`} className="storefront__btn__link ">
                                {lang==="en"?block.name_eng:block.name}
                            </Link>
                            <MdKeyboardArrowRight />
                        </div>
                    </div>
                    <div className="storefront__row">
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={10}
                            pagination={{
                                clickable: true,
                            }}
                            className="mySwiper storefront__swiper">
                            {
                                products&& products.map(product =>
                                    <SwiperSlide className="storefront__swiper__slide" key={product.id}>
                                        <StoreFrontCard isMainPage={true} setCountLikedProducts={setCountLikedProducts} liked={product.was_liked} showContinueShopingModal={showContinueShopingModal} setShowContinueShopingModal={setShowContinueShopingModal}  product={product} key={product.id}/>
                                    </SwiperSlide>
                                )
                            }
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StoreFront;