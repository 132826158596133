import React, {useEffect} from 'react';
import {postData} from "../../utils/customHooks/customHooks";

const SuccessPayment = () => {
    return (
        <div>
            success payment
        </div>
    );
};

export default SuccessPayment;