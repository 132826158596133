import React, {useEffect, useState} from 'react';

import {useTranslation} from "react-i18next";
import "./faq.css";
import FaqQuestion from "./faqQuestion";
import {Link} from "react-router-dom";
import {getData} from "../../utils/customHooks/customHooks";
import {MdKeyboardArrowRight} from "react-icons/md";
import {LuArrowDownCircle} from "react-icons/lu";

function Faq({page, is_main=false}) {
    const {t} = useTranslation();
    const lang = localStorage.getItem("language") || "";
const validLang = lang.trim() === "" ? "" : `/${lang}`;
    const [faqData, setFaqData] = useState(null);
    const [visibleFaq, setVisibleFaq] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


     useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleFaq = (index) => {
        setVisibleFaq(visibleFaq === index ? null : index);
    };

    useEffect(() => {
        if (page === "shop") {
            getData("/api/shop/faq_products/").then(res => {
                setFaqData(res);
            })
        } else {
            getData("/api/shop/faq/").then(res => {
                setFaqData(res);
            })
        }
    }, []);
    return (
        <>
            <section className="faq" id="faq">
                <div className="faq__container container">
                    {!is_main && <div className="page__navigation page__navigation_shopping_cart container">
                        <Link to={`${validLang}`} className="page__navigation__link">
                            {t("nav_home")}
                        </Link>
                        <MdKeyboardArrowRight/>
                        <Link to={lang === "en" ? "/en/faq" : "/faq"} className="page__navigation__link">
                            FAQ
                        </Link>
                    </div>}
                    {!is_main
                        ? <h1 className="faq__title main__title container">
                            FAQ
                        </h1>
                        : <h1 className=" main__title container">
                            FAQ
                        </h1>}

                    {faqData &&
                        <div className="faq__row" style={{...(windowWidth < 760 && { width: "100%"})}}>
                        {faqData.map((faq, index) => (
                                <div style={{width: '100%'}}>
                                    <div className="qaarticle" key={index}  style={{...(windowWidth < 760 ? { width: "auto"}:{width: '600px',})}}>
                                    <p style={{fontSize: 25, cursor: 'pointer', display: 'flex', justifyContent:'space-between', alignItems:'center'}} onClick={() => toggleFaq(index)}>
                                            {lang === "en" ? faq.title_eng : faq.title}
                                            <LuArrowDownCircle/>
                                        </p>
                                        <p style={{display: visibleFaq === index ? 'block' : 'none'}}>
                                            {lang === "en" ? faq.text_eng : faq.text}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </section>
        </>
                        );
                    }

export default Faq;