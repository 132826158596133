import React, { useState } from 'react';

const Review = ({ authorName, comment, rating, date }) => {
  return (
    <div className="reviews__swiper__slide">
      <div className="reviews__swiper__slide__content">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <p className="reviews__author-name">{authorName}</p>
            <div className="reviews__swiper__slide__rating">
              {Array.from({ length: 5 }, (_, index) => (
                <svg
                  key={index}
                  className={`reviews__swiper__slide__rating__icon_${index}`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 47.94 47.94"
                >
                  <defs>
                    <linearGradient id={`reviews__swiper__slide__rating__icon_${index}_0`}>
                      <stop offset="500%" stopColor="#FDBA74" />
                      <stop offset="501%" stopColor="#cccacc" />
                    </linearGradient>
                  </defs>
                  <path
                    d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z"
                    fill={`url(#reviews__swiper__slide__rating__icon_${index}_0)`}
                  />
                </svg>
              ))}
            </div>
          </div>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 48 48"
            style={{ color: 'rgb(83, 83, 83)', fontSize: '25px' }}
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#FFC107"
              d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24 c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
            />
            <path
              fill="#FF3D00"
              d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657 C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
            />
            <path
              fill="#4CAF50"
              d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
            />
            <path
              fill="#1976D2"
              d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
            />
          </svg>
        </div>
        <p className="reviews__text-comment" style={{ marginBottom: '15px' }}>
          {comment}
        </p>
        <div style={{ position: 'absolute', bottom: '15px' }} className="review__swiper_created_at">
          {date}
        </div>
      </div>
    </div>
  );
};

// BlogPreview Component
const BlogPreview = ({ imageUrl, title, link }) => {
  return (
    <div className="swiper-slide blog_preview">
      <a style={{ color: 'black' }} href={link}>
        <div
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            filter: 'brightness(0.5)',
            position: 'absolute',
            inset: '0px',
            zIndex: '1',
          }}
        />
        <div style={{ position: 'relative', zIndex: '2', display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <div style={{ color: 'white' }}>{title}</div>
          <div style={{ color: 'white' }}>{title}</div>
        </div>
      </a>
    </div>
  );
};

// FAQ Component
const FAQ = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="qaarticle" style={{ width: 'auto' }}>
      <p
        style={{ fontSize: '25px', cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="10" />
          <path d="M12 8v8" />
          <path d="m8 12 4 4 4-4" />
        </svg>
      </p>
      <p style={{ display: isOpen ? 'block' : 'none' }}>{answer}</p>
    </div>
  );
};

// Main Component
const speedTest = () => {
  const reviews = [
    {
      authorName: 'Вова Мацьків',
      comment: 'Обслуговування дуже швидке і ввічливе, радує, що в асортименті були ті квіти, які я хотів',
      rating: 5,
      date: '28.01.2025',
    },
    // Add more reviews here
  ];

  const blogPreviews = [
    {
      imageUrl: '/media/blog_img/rozochki_F6kkamH.jpg',
      title: 'роза і фіалочка',
      link: '/ua/blog/roza-i-tyulpan',
    },
    // Add more blog previews here
  ];

  const faqs = [
    {
      question: 'Де можна купити квіти у Львові?',
      answer: 'Ви можете замовити букети та квіти для декорування приміщень в Mur Mur Flowers! Інтернет-магазин квітів, де найдешевші квіти, знаходиться за адресами: вул, Газова 26 (поруч Forum Lviv) та проспект Червоної Калини, 50. Завітайте до нас або оформіть онлайн-замовлення із кур’єрською доставкою.',
    },
    // Add more FAQs here
  ];

  return (
    <div>
      <div className="reviews__container container">
        <h2 className="reviews__title main__title container">Відгуки</h2>
        <div className="reviews__swiper__container">
          <div className="swiper-wrapper">
            {reviews.map((review, index) => (
              <Review key={index} {...review} />
            ))}
          </div>
        </div>
      </div>

      <div className="reviews" id="reviews">
        <div className="reviews__container container">
          <div className="reviews__header">
            <h2 className="reviews__title main__title container">Блог</h2>
            <div className="reviews__header__btn">
              <a className="storefront__btn__link" href="/ua/blog">
                Більше
              </a>
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0V0z" />
                <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
              </svg>
            </div>
          </div>
          <div className="reviews__swiper__container">
            <div className="swiper-wrapper">
              {blogPreviews.map((preview, index) => (
                <BlogPreview key={index} {...preview} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <section className="faq" id="faq">
        <div className="faq__container container">
          <h1 className="main__title container">FAQ</h1>
          <div className="faq__row" style={{ width: '100%' }}>
            {faqs.map((faq, index) => (
              <FAQ key={index} {...faq} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default speedTest;